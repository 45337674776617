import { Link } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import {
  DemoButton,
  GetStartedButton,
  LearnMoreButton,
  TeachmintSection,
  Faq
} from "../commonComponents"

import {
  IntegrationDarkSection
} from "../integrationsUtils"

import { Layout } from "../components/Layout"


export default function Teachmint() {
  return (
    <Layout
      title="Teachmint + IrisAgent: Case Study in Support Automation"
      keywords="Teachmint, IrisAgent customers, Teachmint AI, Teachmint support"
      description="Teachmint leverages IrisAgent to revolutionize customer support, streamlining processes and enhancing satisfaction. Discover how our partnership with Teachmint transforms service delivery in our in-depth case study."
    >
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/teachmint-case-study/"
        />
      </Helmet>
      <main id="main">
        {/*  ======= Hero Section =======  */}
        <section
          id="hero-new-design"
          className="d-flex align-items-center section-padding-left section-padding-right"
          style={{ paddingTop: "5%", paddingBottom: "5%" }}
        >
          <div className="row" style={{ width: "100%", paddingTop: "2%" }}>
          <div className="col-md-6">
              <img
                src="/img/brands/teachmint.svg"
                alt="Teachmint"
                style={{ width: "70%", paddingTop:"30%" }}
              />
            </div>
            <div className="col-md-6">
              <h1 className="title-support-new-design">
                Teachmint solves <span style={{ color: "#EF5DA8", fontWeight: 600 }}>35% customer issues</span> with IrisAgent AI
              </h1>
              <div className="description-support-new-design">
                <span>
                  Teachmint transforms its support experience with automated<br/> triaging, tagging, and self-service resolutions<br/> using IrisAgent AI Support Automation

                </span>
              </div>
            </div>
          </div>
        </section>
        {/*  End Hero  */}
        {/* <!-- ======= Counts Section ======= --> */}
        <TeachmintSection theme="dark" />
        {/* <!-- End Counts Section --> */}


        <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin:"10%" }}>
            <h2><span style={{ color: "#EF5DA8", fontWeight: 600 }}>The Challenge</span></h2>
            <br/>
            Teachmint is a leading provider of education-infrastructure solutions, Since inception, they have enabled millions of educators to create global,future-ready classrooms with proprietary classroom technology. It has adopted a product-led growth strategy. As they grew, they knew that delivering. a superior support experience was key to the retention and growth of the customer base. They invested in a leading CRM tool – Freshdesk to equip frontline support teams to engage via digital channels. However, the support leadership the following challenges:<br/><br/>
            <ul>
                <li><b>Product Insights</b> - Product teams lacked real-time insights into critical product-related issues from customers hindering product-led growth</li><br/>
                <li><b>Efficiency</b> - Support agents spent a lot of time answering the same repetitive questions repeatedly</li><br/>
                <li><b>Effectiveness</b> - Support agents spent too much time and effort manually tagging tickets</li>
            </ul><br/><br/>

            <h2><span style={{ color: "#EF5DA8", fontWeight: 600 }}>The Solution: AI-powered Support Automation</span></h2>
            <br/>
            Teachmint support leadership soon realized that they needed to solve these challenges by developing a systemic approach. They evaluated IrisAgent AI-powered Support Automation to help them automate tagging, streamline workflows, and bring the context and intelligence from engineering teams to the support teams that used Freshdesk CRM.<br/><br/>


            Teachmint implemented the following capabilities,<br/><br/>
            <ul>
            <li><b>IrisGPT chatbot and search</b> for self-service</li>
            <li><b>Automated tagging</b> and intent detection of issues in Freshdesk CRM</li>
            <li><b>AI recommendation engine</b> for identifying AI-powered answers, related product bugs,</li>
            <li>Key reports on <b>trending issues</b> for Product managers to review and incorporate in roadmap planning</li>
             </ul>
            <br/><br/>
            <img
                src="/img/AI_Engine.png"
                alt="Teachmint"
                style={{ width: "90%", marginBottom:"10%" }}
              />
            <br/>

            <h2><span style={{ color: "#EF5DA8", fontWeight: 600 }}>The Results</span></h2>
            <br/>
            Since deploying IrisAgent AI Support Automation, Teachmint has seen,
            <br/><br/>
            <ul>
                <li><b>35%</b> of queries addressed by AI, enabling agents to focus on real impactful issues</li>
                <li><b>10x</b> improvement in Mean Time to Resolution in many scenarios</li>
                <li><b>10x</b> visibility into product issues</li>
                <li>Real-time product quality insights and improved CSAT</li>
            </ul>
            </div>
          </div>
        </section>

      <IntegrationDarkSection
        label={
          <span className="integration-dark-section-title">
            Try out{" "}
            <span className="title-fucsia-color">IrisGPT</span> on your own data for free
          </span>
        }
        button={
          <LearnMoreButton

            label="TRY NOW FOR FREE"
            to="/try-for-free"
          />
        }
        firstColClassName="col-md-8"
        secondColClassName="col-md-4"
        buttonWebFlexAlignment="end"
      />
      {/*  End Cta Section  */}





        {/* <!-- ======= Contact Services Section ======= --> */}
        {/* <ContactSection /> */}
        {/* <!-- End Contact Section --> */}
      </main>
      {/*  End #main  */}
    </Layout>
  )
}
